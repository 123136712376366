<template>
	<!-- 入驻首页 -->
	<div class="enter-page-all">
		<totop></totop>
		<div class="enter-page-up">
			<img src="../../../assets/img/organize/bg.png" class="enter-bgImg">
			<div class="enter-upContent">
				<div class="enter-upTitleOne">欢迎入驻云课堂</div>
				<div class="enter-upTitleTwo ">四大核心优势，实现知识变现</div>
				<div class="enter-upButtonOne" @click="toStepOne()">
					个人讲师入驻申请
					<img src="../../../assets/img/organize/right.png" class="enter-rightIcon">
				</div>
				<div class="enter-upButtonTwo" @click="toStepOne1()">
					机构入驻申请
					<img src="../../../assets/img/organize/right.png" class="enter-rightIcon enter-mrLeft">
				</div>
			</div>
		</div>
		<div class="enter-page-down">
			<!-- 核心优势 -->
			<div>
				<div class="enter-itemTitle">
					<div class="enter-itemTitleOne">ADVANTAGE</div>
					<div class="enter-itemTitleTwo">核心优势</div>
				</div>
				<div class="enter-itemContent">
					<el-row :gutter="20">
						<el-col :span="6">
							<el-card class="enter-box-card">
								<img class="icon-enter-img" src="../../../assets/img/organize/icon1.png">
								<div class="enter-itemTextOne">智能的上课工具</div>
								<div class="enter-itemIcon"></div>
								<div class="enter-itemTextTwo">全新打造的智能上课工具，让课堂更加灵活方便</div>
							</el-card>
						</el-col>
						<el-col :span="6">
							<el-card class="enter-box-card">
								<img class="icon-enter-img" src="../../../assets/img/organize/icon2.png">
								<div class="enter-itemTextOne">先进的机构管理后台</div>
								<div class="enter-itemIcon"></div>
								<div class="enter-itemTextTwo">全方位一站式打造内容、营销、教学服务 、用户管理等免费工具</div>
							</el-card>
						</el-col>
						<el-col :span="6">
							<el-card class="enter-box-card">
								<img class="icon-enter-img" src="../../../assets/img/organize/icon3.png">
								<div class="enter-itemTextOne">超强的推广力度</div>
								<div class="enter-itemIcon"></div>
								<div class="enter-itemTextTwo">全新打造的智能上课工具，让课堂更加灵活方便</div>
							</el-card>
						</el-col>
						<el-col :span="6">
							<el-card class="enter-box-card">
								<img class="icon-enter-img" src="../../../assets/img/organize/icon4.png">
								<div class="enter-itemTextOne">流量分成</div>
								<div class="enter-itemIcon"></div>
								<div class="enter-itemTextTwo">全新打造的智能上课工具，让课堂更加灵活方便</div>
							</el-card>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- 入驻流程 -->
			<div>
				<div class="enter-itemTitle">
					<div class="enter-itemTitleOne">PROCESS</div>
					<div class="enter-itemTitleTwo">入驻流程</div>
				</div>
				<div class="enter-itemContent">
					<el-row>
						<el-col :span="6">
							<img class="enter-img" src="../../../assets/img/organize/processIcon1.png">
							<div class="enter-itemTextOne">提交入驻资料</div>
							<div class="enter-itemTextTwo">填写入驻基本信息、教学经验成果提交</div>
						</el-col>
						<el-col :span="3">
							<img src="../../../assets/img/organize/right1.png" class="style100 style70w49h">
						</el-col>
						<el-col :span="6">
							<img class="enter-img" src="../../../assets/img/organize/processIcon2.png">
							<div class="enter-itemTextOne">等待审核结果</div>
							<div class="enter-itemTextTwo">审核时间约 1-3个工作日</div>
						</el-col>
						<el-col :span="3">
							<img src="../../../assets/img/organize/right1.png" class="style100 style70w49h">
						</el-col>
						<el-col :span="6">
							<img class="enter-img" src="../../../assets/img/organize/processIcon3.png">
							<div class="enter-itemTextOne">审核通过</div>
							<div class="enter-itemTextTwo">可发布录播课程、直播课堂</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- 已入驻教师 -->
			<div>
				<div class="enter-itemTitle">
					<div class="enter-itemTitleOne">TEACHER</div>
					<div class="enter-itemTitleTwo enter-mr">已入驻教师</div>
				</div>
				<div class="enter-itemContent">
					<el-row :gutter="80">
						<el-col :span="12" class="enter-process1">
							<img src="../../../assets/img/organize/teacher1.png" class="enter-teacherIcon">
							<div class="enter-itemBg">
								<div class="enter-itemIns">
									<div>Sunny</div>
									<div>网易首席设计师</div>
								</div>
							</div>
						</el-col>
						<el-col :span="12" class="enter-process1">
							<img src="../../../assets/img/organize/teacher4.png" class="enter-teacherIcon style6">
							<div class="enter-itemBg">
								<div class="enter-itemIns">
									<div>Sunny</div>
									<div>网易首席设计师</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="80">
						<el-col :span="12" class="enter-process1">
							<img src="../../../assets/img/organize/teacher3.png" class="enter-teacherIcon stylef4">
							<div class="enter-itemBg">
								<div class="enter-itemIns">
									<div>Sunny</div>
									<div>网易首席设计师</div>
								</div>
							</div>
						</el-col>
						<el-col :span="12" class="enter-process1">
							<img src="../../../assets/img/organize/teacher2.png" class="enter-teacherIcon stylez4">
							<div class="enter-itemBg">
								<div class="enter-itemIns">
									<div>Sunny</div>
									<div>网易首席设计师</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import totop from '../../common/totop.vue';
	export default {
		components: {
			totop
		},
		data() {
			return {
				teacherlist: []
			};
		},
		created() {
			this.getteacher()
		},
		methods: {
			// 入驻按钮(个人)
			toStepOne() {
				this.$router.push({
					path: '/stepOne',
					query: {
						id: 2
					}
				})
			},
			//机构讲师
			getteacher() {
				this.$get("school/schoolteacherlist", {
					school_id: 0,
					list_rows: 4
				}).then(response => {
					if (response.error == 0) {
						this.teacherlist = response.data.teacherList.data
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			// 入驻按钮(机构)
			toStepOne1() {
				this.$router.push({
					path: '/stepOne',
					query: {
						id: 1
					}
				})
			},

		}
	};
</script>

<style>
	.enter-page-all {
		width: 100%;
		height: auto;
		background-color: white;
	}

	.enter-page-up {
		width: 100%;
		height: 500px;
		margin-bottom: 100px;
	}

	.enter-bgImg {
		width: 100%;
		height: 500px;
		float: left;
	}

	.enter-upContent {
		width: 500px;
		height: auto;
		position: absolute;
		margin-top: 76px;
		margin-left: 240px;
		text-align: center;
		cursor: pointer;
	}

	.enter-upTitleOne {
		font-size: 68px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.enter-upTitleTwo {
		font-size: 30px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 20px;
	}

	.enter-upButtonOne {
		width: 350px;
		height: 70px;
		background: #00D0B0;
		font-size: 26px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		margin: 0 auto;
		line-height: 70px;
		margin-top: 26px;
	}

	.enter-upButtonTwo {
		width: 350px;
		height: 70px;
		border: 1px solid #FFFFFF;
		font-size: 26px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		margin: 0 auto;
		margin-top: 26px;
		line-height: 70px;
	}

	.enter-rightIcon {
		position: absolute;
		width: 28px;
		height: 28px;
		margin-top: 22px;
		margin-left: 30px;
	}

	.enter-mrLeft {
		margin-left: 56px;
	}

	.enter-page-down {
		width: 100%;
		height: auto;
	}

	.enter-itemTitle {
		width: 40%;
		height: auto;
		margin: 0 auto;
		text-align: center;
		overflow: hidden;
	}

	.enter-itemTitleOne {
		font-size: 84px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: rgba(51, 51, 51, 0);
		opacity: 0.3;
		-webkit-text-stroke: 1px #854F17;
		text-stroke: 1px #854F17;
	}

	.enter-itemTitleTwo {
		font-size: 48px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		position: absolute;
		margin-top: -90px;
		margin-left: 14%;
	}

	.enter-mr {
		margin-left: 12.5%;
	}

	.enter-itemContent {
		width: 70%;
		height: auto;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 80px;
		text-align: center;
		cursor: pointer;
	}

	.enter-itemTextOne {
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		margin-top: 35px;
	}

	.enter-itemIcon {
		width: 48px;
		height: 3px;
		background: #00D0B0;
		margin: 0 auto;
		margin-top: 23px;
	}

	.enter-itemTextTwo {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
		margin-top: 20px;
	}

	.enter-box-card {
		width: 250px;
		height: 250px;
		text-align: center;
	}

	/* .process{
		height: 300px;
		text-align: center;
	} */
	.enter-process1 {
		height: 350px;
		display: flex;
		justify-content: space-between;
	}
	.enter-itemBg {
		width: 450px;
		height: 158px;
		background: #CCF6EF;
		box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.15);
		margin-top: 150px;
		float: left;
		margin-left: 70px;
	}

	.enter-itemIns {
		position: absolute;
		margin-top: 48px;
		margin-left: 250px;
		text-align: center;
	}

	.enter-itemIns div:first-child {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
	}

	.enter-itemIns div:last-child {
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}


	.enter-img {
		height: 154px;
		width: 154px;
	}

	.icon-enter-img {
		height: 57px;
		width: 57px;
	}

	/* 补回来的样式 */
	.style100 {
		margin-top: 100px;
	}

	.style6 {
		margin-top: 6px;
	}

	.stylef4 {
		margin-top: -4px;
	}

	.stylez4 {
		margin-top: 4px;
	}

	.style70w49h {
		width: 70px;
		height: 49px;
	}

	/* 增加了一些的效果 */
	.enter-teacherIcon {
		width: 222px;
		height: 314px;
		margin-left: 60px;
		position: absolute;
	}
</style>
